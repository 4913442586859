(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["magica11y"] = factory();
	else
		root["magica11y"] = root["magica11y"] || {}, root["magica11y"]["prefersReducedTransparency"] = factory();
})(self, function() {
return 